import React from 'react';
import ReactDOM from "react-dom"
import SecondsConverter from "../shared/components/seconds_converter"

document.addEventListener("DOMContentLoaded", () => {
  const nodes = document.getElementsByClassName("seconds_field")
  for (var i = 0; i < nodes.length; i++) {
    const seconds = nodes[i].value;
    const name = nodes[i].getAttributeNode("name");
    const id = nodes[i].getAttributeNode("id");
    const className = nodes[i].getAttributeNode("class");

    var newDiv = document.createElement("div");
    nodes[i].insertAdjacentElement("afterend", newDiv);
    nodes[i].removeAttributeNode(name);
    nodes[i].removeAttributeNode(id);
    nodes[i].className = nodes[i].className + " hidden";

    ReactDOM.render(<SecondsConverter name={name.value} id={id.value} className={className.value} seconds={seconds} />, newDiv);
  }
});
