import React, { Component } from "react"
import PropTypes from 'prop-types'

import ReactDOM from "react-dom"

class SecondsConverter extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {
      seconds: this.props.seconds
    }
  }

  componentDidMount(){
    this.updateDropDowns();
  }

  updateSeconds = (event) => {
    let days = this.refs.num_days.value || 0;
    let hrs = this.refs.num_hrs.value || 0;
    let mins = this.refs.num_mins.value || 0;

    let seconds = (days * 86400) + (hrs * 3600) + (mins * 60)
    if(seconds == 0){ seconds = ""; }
    this.setState({seconds, seconds});
  };

  updateDropDowns = () => {
    var mins = Math.floor(this.state.seconds/60);
    var hrs = Math.floor(mins/60);
    var days = Math.floor(hrs/24);

    hrs = hrs-(days*24);
    mins = mins-(days*24*60)-(hrs*60);

    this.refs.num_mins.value = mins;
    this.refs.num_hrs.value = hrs;
    this.refs.num_days.value = days;
  }

  render() {
    return(
      <div className="seconds_selector">
        <input className="form-control hidden" id={this.props.id} name={this.props.name} value={this.state.seconds} readOnly></input>
        <form className="form-inline">
          <div className="form-group">
            <label >Day</label>
            <input style={{width: "60px"}} type="number" name="num_days" className="form-control" onChange={this.updateSeconds} ref="num_days"></input>
          </div>
          <div className="form-group">
            <label>Hours</label>
            <input style={{width: "60px"}} type="number" name="num_hrs" className="form-control" onChange={this.updateSeconds} ref="num_hrs"></input>
          </div>
          <div className="form-group">
            <label >Minutes</label>
            <input style={{width: "60px"}} type="number" name="num_mins" className="form-control" onChange={this.updateSeconds} ref="num_mins"></input>
          </div>
        </form>
      </div>
    )
  }
}

export default SecondsConverter;
